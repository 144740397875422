import React from 'react';
import useQuery from 'services/useQuery';
import { LoadScript } from '@react-google-maps/api';
import { useAuth } from '../../data/OneTimeCarrierAuth';
import styles from './OneTimeCarrierContainer.module.scss';
import OneTimeCarrierForm from '../OneTimeCarrierForm';
import OneTimeCarrierPassing from '../OneTimeCarrierPassing';

const OneTimeCarrierContainer = () => {
    const { token } = useAuth();
    const language = localStorage.getItem('language') ?? 'en';
    const mapsApiKey = 'AIzaSyDAiJx7uB2oX_20sXq08AUJEoXxhpxr4-A';
    const localMapsApiKey = 'AIzaSyBjFoGES5Kba_RN4vHxnC0IMWGxyA0YR-M';


    const query = useQuery();
    const phone = query.has('phone') ? query.get('phone') : '';

    if (!token || token.length === 0) {
        return (
            <div className={styles.Container}>
                <OneTimeCarrierForm phone={phone} />
            </div>
        );
    }

    return (
        <LoadScript
            googleMapsApiKey={window.location.host === 'localhost:3000' ? localMapsApiKey : mapsApiKey}
            language={language}
        >
            <OneTimeCarrierPassing />
        </LoadScript>
    );
};

export default OneTimeCarrierContainer;
