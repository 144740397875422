import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import querystring from 'querystring';
import { API, errorHandler } from 'services';
import { CancelToken } from 'axios';
import Error from 'components/Error';
import SmallLoader from 'components/SmallLoader';
import './styles.scss';
import { transportDraftReset } from 'data/transportDraft/actions';
import draftValidation from '../../services/draftValidation';
import useTransportInit from './services/useTransportInit';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_ADR,
    TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION, TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER,
    TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION, TRANSPORT_ADD_SUBMISSION_STATUS_FILES,
    TRANSPORT_ADD_SUBMISSION_STATUS_FINISH,
    TRANSPORT_ADD_SUBMISSION_STATUS_INIT, TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD,
    TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE,
    TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE,
    TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING,
    TRANSPORT_ADD_SUBMISSION_STATUS_SENDER,
    TRANSPORT_ADD_SUBMISSION_STATUS_STARTING
} from './services/submissionStatuses';
import useTransportRemovePoint from './services/useTransportRemovePoint';
import useTransportPoint from './services/useTransportPoint';
import useTransportRemoveLoad from './services/useTransportRemoveLoad';
import useTransportUpdateLoad from './services/useTransportUpdateLoad';
import useTransportAddLoad from './services/useTransportAddLoad';
import useTransportAdr from './services/useTransportAdr';
import useTransportFiles from './services/useTransportFiles';
import useTransportDriver from './services/useTransportDriver';
import getStatusTranslation from './services/getStatusTranslation';

const TransportAccept = ({
    edit, isValid, transportDraft, reset,
}) => {
    const cancelToken = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [finishLoading, setFinishLoading] = useState(false);
    const [finishError, setFinishError] = useState(false);
    const [status, setStatus] = useState(TRANSPORT_ADD_SUBMISSION_STATUS_INIT);
    const {
        loadTimeStart, loadTimeEnd, unloadTimeStart, unloadTimeEnd,
        loadSummary, specialProvisions, senderInstructions,
        sender, receiver, startingAddress, destinationAddress, loads,
        adr, documents, carrier, contractor,
        oneTimeCarrier,
    } = transportDraft;
    const initTransport = useTransportInit(status, {
        is_working_copy: edit ? 0 : 1,
        load_time_start: loadTimeStart.length ? loadTimeStart : null,
        load_time_end: loadTimeEnd.length ? loadTimeEnd : null,
        unload_time_start: unloadTimeStart.length ? unloadTimeStart : null,
        unload_time_end: unloadTimeEnd.length ? unloadTimeEnd : null,
        load_summary_description: loadSummary.length ? loadSummary : null,
        special_provisions: specialProvisions.length ? specialProvisions : null,
        sender_instructions: senderInstructions.length ? senderInstructions : null,
    }, edit ? id : false, isValid);
    const { transport } = initTransport;
    const removePoints = useTransportRemovePoint(status, transport || false);
    const addPoints = useTransportPoint(status, transportDraft, transport);
    const removeLoads = useTransportRemoveLoad(status, loads, transport);
    const updateLoads = useTransportUpdateLoad(status, loads, transport);
    const addLoads = useTransportAddLoad(status, loads, transport);
    const updateAdr = useTransportAdr(status, adr, transport);
    const updateFiles = useTransportFiles(status, documents, transport);
    const updateCarrier = useTransportDriver(
        status, carrier, contractor, transport, oneTimeCarrier
    );
    const loading = initTransport.isLoading || removePoints.isLoading
        || addPoints.isLoading || removeLoads.isLoading
    || updateLoads.isLoading || addLoads.isLoading
    || updateAdr.isLoading || updateFiles.isLoading
    || updateCarrier.isLoading
    || finishLoading;
    let error = false;
    if (initTransport.error !== false) {
        error = initTransport.error;
    } else if (removePoints.error !== false) {
        error = removePoints.error;
    } else if (addPoints.error !== false) {
        error = addPoints.error;
    } else if (removeLoads.error !== false) {
        error = removeLoads.error;
    } else if (updateLoads.error !== false) {
        error = updateLoads.error;
    } else if (addLoads.error !== false) {
        error = addLoads.error;
    } else if (updateAdr.error !== false) {
        error = updateAdr.error;
    } else if (updateFiles.error !== false) {
        error = updateFiles.error;
    } else if (updateCarrier.error !== false) {
        error = updateCarrier.error;
    } else if (finishError !== false) {
        error = finishError;
    }

    useEffect(() => {
        let t1 = null;
        if (!isValid) {
            setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION);
        } else if (error === false && !loading && transport !== false) {
            t1 = setTimeout(() => {
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_INIT
                    && transport.sender && sender.name.length === 0) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER);
                    return;
                }
                if ((status === TRANSPORT_ADD_SUBMISSION_STATUS_INIT
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER)
                    && transport.receiver && receiver.name.length === 0) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER);
                    return;
                }
                if ((status === TRANSPORT_ADD_SUBMISSION_STATUS_INIT
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER)
                    && transport.starting_address && startingAddress.name.length === 0) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING);
                    return;
                }
                if ((status === TRANSPORT_ADD_SUBMISSION_STATUS_INIT
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING)
                    && transport.destination_address && destinationAddress.name.length === 0) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_INIT
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING
                    || status === TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_SENDER);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_SENDER) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_STARTING);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_STARTING) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_ADR);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_ADR) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_FILES);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_FILES) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER);
                    return;
                }
                if (status === TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER
                    && status !== TRANSPORT_ADD_SUBMISSION_STATUS_FINISH) {
                    setStatus(TRANSPORT_ADD_SUBMISSION_STATUS_FINISH);
                }
            }, 30);
        }

        return () => {
            clearTimeout(t1);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, transport, error, loading]);

    useEffect(() => {
        if (error === false && !loading && status === TRANSPORT_ADD_SUBMISSION_STATUS_FINISH) {
            setFinishLoading(true);
            cancelToken.current = CancelToken.source();
            API.put(`/passings/${transport.id}`, querystring.stringify({
                is_working_copy: 0,
            }), {
                cancelToken: cancelToken.current.token,
            }).then(() => {
                documents.forEach((document) => {
                    if (document.objectURL) {
                        URL.revokeObjectURL(document.objectURL);
                    }
                });
                reset(edit);
                setFinishLoading(false);
                history.push(`/transports/single/${transport.id}`);
            }).catch((er) => {
                errorHandler(er, () => {
                    setFinishError(er);
                    setFinishLoading(false);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, loading, error]);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);


    return (
        <div className="TransportAccept">
            <div style={{ width: '100%' }}>{error && <Error errors={error} /> }</div>
            {(!error && (loading
            || (status !== TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION
                && status !== TRANSPORT_ADD_SUBMISSION_STATUS_FINISH))) && <SmallLoader dark />}
            {status === TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION
            && <Error errors={getStatusTranslation(status)} /> }
            {!error && status !== TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION
            && <div className="TransportAccept__status">{getStatusTranslation(status)}</div> }
        </div>
    );
};

TransportAccept.propTypes = {
    edit: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    isValid: PropTypes.bool.isRequired,
    transportDraft: PropTypes.shape({
        sender: PropTypes.object,
        receiver: PropTypes.object,
        destinationAddress: PropTypes.object,
        startingAddress: PropTypes.object,
        loadTimeStart: PropTypes.string,
        loadTimeEnd: PropTypes.string,
        unloadTimeStart: PropTypes.string,
        unloadTimeEnd: PropTypes.string,
        senderDifferent: PropTypes.bool,
        receiverDifferent: PropTypes.bool,
        carrier: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        isEdit: PropTypes.bool,
        driver: PropTypes.number,
        contractor: PropTypes.object,
        specialProvisions: PropTypes.string,
        senderInstructions: PropTypes.string,
        documents: PropTypes.arrayOf(PropTypes.object),
        adr: PropTypes.object,
        extendedLoad: PropTypes.bool,
        loadSummary: PropTypes.string,
        loads: PropTypes.arrayOf(PropTypes.object),
        oneTimeCarrier: PropTypes.object,
    }).isRequired,
    reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const validation = draftValidation(transportDraft);
    const { sender, receiver, driver } = validation;
    const isValid = sender.validation && receiver.validation && driver.validation;

    return {
        isValid, transportDraft,
    };
};


const mapDispatchToProps = (dispatch) => ({
    reset: (isEdit) => dispatch(transportDraftReset(false, isEdit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportAccept);
