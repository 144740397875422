import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import API from 'services/API';
import { CancelToken } from 'axios';
import classNames from 'services/classNames';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Error from 'components/Error';
import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
import Success from 'components/Success';

import './styles.scss';
import errorHandler from 'services/errorHandler';
import { hasPermission } from 'services';
import translations from 'services/translations';
import Button from 'components/Button';
import UsersListElement from './components/UsersListElement';
import UserDetails from './components/UserDetails';
import UserAdd from './components/UserAdd';
import DeletedUserAlert from './components/DeletedUserAlert';

const Users = ({ perPage, isAdd, canManageTeam }) => {
    const history = useHistory();
    const { page, user } = useParams();
    const pageNumber = page ? parseInt(page, 10) : 1;
    const [loading, setLoading] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [redirectTo, setRedirectTo] = useState(0);
    const [lastPage, setLastPage] = useState(pageNumber);
    const [formError, setFormError] = useState(null);
    const onPage = perPage.users ? perPage.users : 10;
    const [currentUser, setCurrentUser] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(0);
    const [userError, setUserError] = useState(false);
    const [message, setMessage] = useState('');

    const paginationUpdate = (meta) => {
        let redirectToSet = 0;
        if (meta) {
            if (meta.last_page) {
                setLastPage(meta.last_page);
            }
            if (meta.current_page
                && meta.last_page
                && meta.current_page > meta.last_page) {
                redirectToSet = meta.last_page;
            }
        }
        if (redirectToSet !== 0) {
            setRedirectTo(redirectToSet);
        }
    };

    useEffect(() => {
        setLoading(true);
        const source = CancelToken.source();
        API.get(`/users?page[size]=${onPage}&page[number]=${page}`, {
            cancelToken: source.token,
        })
            .then((response) => {
                paginationUpdate(response.data.meta);
                if (response.data.data.length) {
                    setUsersList(response.data.data);
                } else {
                    setUsersList([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setUsersList([]);
                    setFormError(error);
                    setLoading(false);
                });
            });

        return () => {
            source.cancel();
        };
    }, [onPage, page, triggerRefresh]);

    useEffect(() => {
        if (canManageTeam && user) {
            const source = CancelToken.source();
            setIsUserLoading(true);
            API.get(`/users/${user}`, {
                cancelToken: source.token,
            })
                .then((response) => {
                    if (response.data.data) {
                        setCurrentUser(response.data.data);
                    } else {
                        setCurrentUser(null);
                    }
                    setIsUserLoading(false);
                    setUserError(false);
                })
                .catch((error) => {
                    errorHandler(error, () => {
                        setCurrentUser(null);
                        setIsUserLoading(false);
                        setUserError(error);
                    });
                });
            return () => {
                source.cancel();
            };
        }

        return () => {};
    }, [user, triggerRefresh, canManageTeam]);


    if (redirectTo > 0) {
        history.push(`/users/list/${redirectTo}`);
        window.location.reload();
        return <div />;
    }

    let usersTemplate = '';
    if (usersList.length) {
        usersTemplate = usersList.map((elm) => (
            <UsersListElement userData={elm} key={elm.id} isAdd={isAdd} />
        ));
    }

    return (
        <>
            <div className="Users__nav">
                <Button
                    withPlus
                    small
                    onClick={() => {
                        history.push('/users/add');
                    }}
                >
                    {translations('route.api.user.store')}
                </Button>
            </div>
            <DeletedUserAlert />
            <div className={classNames('Users', (user || isAdd) && 'with-user')}>
                {formError && <Error errors={formError} />}
                {message && message.length > 0 && <Success message={message} />}
                <div className="Users__list">
                    {loading ? <Loader hideText /> : usersTemplate }
                </div>
                {!isAdd && user && canManageTeam && (
                    isUserLoading ? <Loader hideText />
                        : (
                            <div className="Users__user">
                                {userError && <Error errors={userError} /> }
                                {currentUser && (
                                    <>
                                        <NavLink to={`/users/list/${page || ''}`} className="Users--open-list" />
                                        <UserDetails
                                            user={currentUser}
                                            triggerFunction={() => {
                                                setTriggerRefresh((v) => v + 1);
                                            }}
                                            setMessage={setMessage}
                                        />
                                    </>
                                )}
                            </div>
                        )
                )}
                {isAdd && (
                    <div className="Users__user">
                        <NavLink to={`/users/list/${page || ''}`} className="Users--open-list" />
                        <UserAdd
                            triggerFunction={() => {
                                setTriggerRefresh((v) => v + 1);
                            }}
                            setMessage={setMessage}
                        />
                    </div>
                )}
            </div>
            <Pagination keyName="users" urlPrefix="/users/list/" lastPage={lastPage} currentPage={pageNumber} />
        </>
    );
};

Users.propTypes = {
    perPage: PropTypes.shape({
        passings: PropTypes.number,
        trucks: PropTypes.number,
        users: PropTypes.number,
    }).isRequired,
    isAdd: PropTypes.bool,
    canManageTeam: PropTypes.bool.isRequired,
};

Users.defaultProps = {
    isAdd: false,
};

const mapStateToProps = (state) => {
    const { perPage, user } = state;
    const { permissions } = user;
    const canManageTeam = hasPermission('team_manage', permissions);
    return {
        perPage,
        canManageTeam,
    };
};

export default connect(mapStateToProps, null)(Users);
