const translations = {
    loading: 'Laadimine',
    loading_data: 'Andmete laadimine ...',
    validate_email: 'See ei ole õige e-posti aadress',
    validate_required: 'Selle välja täitmine on kohustuslik',
    validate_the_same: 'Mõlemad paroolid peavad olema samad',
    sign_in: 'Logi sisse',
    sign_up__invitation: 'Kas teil pole kontot?',
    sign_up: 'Registreeri!',
    forgot_password: 'Kas sa ei mäleta parooli?',
    error_occurred: 'Tekkis viga!',
    activation_token_broken: 'Aktiveerimismärk on vale.',
    return_to_login: 'Tagasi sisselogimiseks',
    activation_success: 'Aitäh! Teie konto on nüüd aktiivne.',
    forgot_password_submit: 'Lähtestage parool',
    password: 'Parool',
    password_confirmation: 'Korda salasõna',
    email: 'E-kiri',
    remember_me: 'Mäleta mind',
    welcome: 'Tere tulemast!',
    log_in_to_access: 'Logi sisse, et alustada',
    password_forgot_message: 'Kas te ei saa sisse logida? Sisestage oma parooli lähtestamiseks oma e-posti aadress!',
    back_to: 'tagasi pöörduma',
    sign_in_page: 'Logi sisse',
    select: 'Vali ...',
    select_new: 'Loo:',
    invitation_sent: 'Kutse saadetud',
    users_custom_company_data: 'Kasutage muid ettevõtte andmeid',
    or_just_attachment_name: 'Või lisa lihtsalt manusenimi:',
    other_docs: 'Teised dokumendid:',
    readBy: 'Loetud:',
    delete_user: 'Kustuta see kasutaja',
    delete_user_confirm: 'Kas olete kindel, et soovite selle kasutaja kustutada?',
    deleted_user_success: 'Kasutaja on edukalt kustutatud',
    transport_one_time: 'Ühekordne transport',
    trailers_registration_number: 'Haagise registreerimisnumber',
    vehicle_registration_number: 'Sõiduki registreerimisnumber',
    one_time_carrier_form_title: 'Sellele transpordile juurdepääsuks sisestage oma telefoninumber ja PIN-kood',
    one_time_carrier_form_error: 'Transportile juurdepääs ebaõnnestus. Kontrollige oma andmeid ja proovige uuesti.',
    one_time_carrier_form_submit: 'Kontrolli transporti',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Teil on juurdepääs järgmisele transpordile:',
    one_time_carrier_ecmr: 'e-CMR dokument:',
    one_time_carrier_signatures: 'Allkirjad:'
};
export default translations;
