const translations = {
    loading: 'Carregando',
    loading_data: 'Carregamento de dados ...',
    validate_email: 'Este não é o endereço de e-mail correto',
    validate_required: 'Este campo é obrigatório',
    validate_the_same: 'Ambas as senhas devem ser as mesmas',
    sign_in: 'Entrar',
    sign_up__invitation: 'Você não tem uma conta?',
    sign_up: 'Registro!',
    forgot_password: 'Você não se lembra da senha?',
    error_occurred: 'Um erro ocorreu!',
    activation_token_broken: 'O token de ativação está incorreto.',
    return_to_login: 'Retornar ao login',
    activation_success: 'Obrigada! Sua conta agora está ativa.',
    forgot_password_submit: 'Redefinir a senha',
    password: 'Senha',
    password_confirmation: 'Repita a senha',
    email: 'E-mail',
    remember_me: 'Lembre de mim',
    welcome: 'Bem-vinda!',
    log_in_to_access: 'Faça o login para começar',
    password_forgot_message: 'Você não pode fazer login? Digite seu endereço de e-mail para redefinir sua senha!',
    back_to: 'volta para',
    sign_in_page: 'Conecte-se',
    select: 'Escolher...',
    select_new: 'Crio:',
    invitation_sent: 'Convite enviado',
    users_custom_company_data: 'Utilize outros dados da empresa',
    or_just_attachment_name: 'Ou apenas adicione o nome do anexo:',
    other_docs: 'Outros documentos:',
    readBy: 'Lido por:',
    delete_user: 'Apagar este usuário',
    delete_user_confirm: 'Tem certeza de que deseja apagar este usuário?',
    deleted_user_success: 'O usuário foi apagado com sucesso',
    transport_one_time: 'Transporte único',
    trailers_registration_number: 'Número de registro do reboque',
    vehicle_registration_number: 'Número de registro do veículo',
    one_time_carrier_form_title: 'Para acessar este transporte, insira seu número de telefone e PIN',
    one_time_carrier_form_error: 'Falha ao acessar o transporte. Verifique seus dados e tente novamente.',
    one_time_carrier_form_submit: 'Verificar transporte',
    phone: 'Telefone',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Você obteve acesso ao seguinte transporte:',
    one_time_carrier_ecmr: 'Documento e-CMR:',
    one_time_carrier_signatures: 'Assinaturas:'
};
export default translations;
