import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { classNames } from 'services';
import style from './PopupAlerts.module.scss';

const PopupAlerts = ({
    title, children, close, confirm, hideConfirm, type,
}) => (
    <div className={classNames(style.PopupAlerts, style[`PopupAlerts--${type}`])}>
        <div className={style.Overlay} />
        <div className={style.Content}>
            <div className={style.Title}>
                <div style={{ width: '100%' }}>{title}</div>
            </div>
            <div className={style.Message}>{children}</div>
            <div className={style.Buttons}>
                <Button type="button" theme="outline" medium onClick={close}>Anuluj</Button>
                {!hideConfirm && <Button type="button" onClick={confirm} medium>Ok</Button>}
            </div>
        </div>
    </div>
);

PopupAlerts.propTypes = {
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    hideConfirm: PropTypes.bool,
    type: PropTypes.oneOf(['default', 'error', 'success', 'warning']),
};

PopupAlerts.defaultProps = {
    hideConfirm: false,
    type: 'default',
};

export default PopupAlerts;
