const translations = {
    loading: 'Načítava',
    loading_data: 'Dátové načítanie ...',
    validate_email: 'Toto nie je správna e-mailová adresa',
    validate_required: 'Toto pole sa vyžaduje',
    validate_the_same: 'Obe heslá musia byť rovnaké',
    sign_in: 'Prihlásiť sa',
    sign_up__invitation: 'Nemáte účet?',
    sign_up: 'Registrovať!',
    forgot_password: 'Nepamätáte si heslo?',
    error_occurred: 'Vyskytla sa chyba!',
    activation_token_broken: 'Aktivačný token je nesprávny.',
    return_to_login: 'Návrat k prihláseniu',
    activation_success: 'Ďakujem! Váš účet je teraz aktívny.',
    forgot_password_submit: 'Resetovať heslo',
    password: 'Heslo',
    password_confirmation: 'zopakujte heslo',
    email: 'E-mail',
    remember_me: 'Pamätáš si ma',
    welcome: 'Vitajte!',
    log_in_to_access: 'Ak chcete začať, sa prihláste',
    password_forgot_message: 'Nemôžete sa prihlásiť? Zadajte svoju e-mailovú adresu, aby ste obnovili svoje heslo!',
    back_to: 'vrátiť sa späť k',
    sign_in_page: 'Prihlásiť sa',
    select: 'Vyberte si ...',
    select_new: 'Vytvoriť:',
    invitation_sent: 'Pozvánka odoslaná',
    users_custom_company_data: 'Použite iné firemné údaje',
    or_just_attachment_name: 'Alebo jednoducho pridajte názov prílohy:',
    other_docs: 'Iné dokumenty:',
    readBy: 'Prečítané od:',
    delete_user: 'Zmazať tohto používateľa',
    delete_user_confirm: 'Ste si istí, že chcete zmazať tohto používateľa?',
    deleted_user_success: 'Používateľ bol úspešne zmazaný',
    transport_one_time: 'Jednorazová preprava',
    trailers_registration_number: 'Evidenčné číslo prívesu',
    vehicle_registration_number: 'Evidenčné číslo vozidla',
    one_time_carrier_form_title: 'Pre prístup k tejto preprave zadajte svoje telefónne číslo a PIN',
    one_time_carrier_form_error: 'Nepodarilo sa získať prístup k preprave. Skontrolujte údaje a skúste to znova.',
    one_time_carrier_form_submit: 'Skontrolovať prepravu',
    phone: 'Telefón',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Získali ste prístup k nasledujúcej preprave:',
    one_time_carrier_ecmr: 'Dokument e-CMR:',
    one_time_carrier_signatures: 'Podpisy:'
};
export default translations;
