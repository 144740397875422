const translations = {
    loading: 'Yükleniyor',
    loading_data: 'Veri yükleniyor...',
    validate_email: 'Bu geçerli bir e-posta adresi değil',
    validate_required: 'Bu alan gereklidir',
    validate_the_same: 'İki şifre de aynı değerde olmalıdır',
    sign_in: 'Giriş yap',
    sign_up__invitation: 'Hesabınız yok mu?',
    sign_up: 'Kaydol',
    forgot_password: 'Şifrenizi mi unuttunuz?',
    error_occurred: 'Beklenmedik bir hata oluştu! Lütfen tekrar deneyin.',
    activation_token_broken: 'Aktivasyon kodu bozulmuş.',
    return_to_login: 'Giriş sayfasına dön',
    activation_success: 'Teşekkürler! Hesabınız şimdi aktif.',
    forgot_password_submit: 'Şifreyi sıfırla',
    password: 'Şifre',
    password_confirmation: 'Şifreyi onayla',
    email: 'E-posta',
    remember_me: 'Beni hatırla',
    welcome: 'Hoş geldiniz',
    log_in_to_access: 'Hesabınıza erişmek için giriş yapın',
    password_forgot_message: 'Giriş yapamıyor musunuz? Şifrenizi sıfırlamak için lütfen e-posta adresinizi girin!',
    back_to: 'Geri dön',
    sign_in_page: 'giriş sayfası',
    select: 'Seç...',
    select_new: 'Oluştur:',
    invitation_sent: 'Davetiye gönderildi',
    users_custom_company_data: 'Farklı şirket verilerini kullanın',
    or_just_attachment_name: 'Veya sadece ek adını ekleyin:',
    other_docs: 'Diğer belgeler:',
    readBy: 'Tarafından okundu:',
    delete_user: 'Bu kullanıcıyı sil',
    delete_user_confirm: 'Bu kullanıcıyı silmek istediğinizden emin misiniz?',
    deleted_user_success: 'Kullanıcı başarıyla silindi',
    transport_one_time: 'Tek seferlik taşıma',
    trailers_registration_number: 'Römork plaka numarası',
    vehicle_registration_number: 'Araç plaka numarası',
    one_time_carrier_form_title: 'Bu taşıma için erişim sağlamak amacıyla telefon numaranızı ve PIN kodunuzu girin',
    one_time_carrier_form_error: 'Taşıma erişimi başarısız oldu. Bilgilerinizi kontrol edin ve tekrar deneyin.',
    one_time_carrier_form_submit: 'Taşımayı kontrol et',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Aşağıdaki taşıma için erişim sağladınız:',
    one_time_carrier_ecmr: 'e-CMR belgesi:',
    one_time_carrier_signatures: 'İmzalar:'
};
export default translations;
