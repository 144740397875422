import React from 'react';
import useQuery from 'services/useQuery';
import Alert from 'components/Alert';
import { defaultTranslations } from 'services';
import styles from './DeletedUserAlert.module.scss';

const DeletedUserAlert = () => {
    const query = useQuery();


    if (!query.get('deleted')) {
        return null;
    }

    const lang = localStorage.getItem('language') ?? 'en';
    const translation = defaultTranslations('deleted_user_success', lang);

    return <div className={styles.DeletedUserAlert}><Alert type="success"><big>{translation}</big></Alert></div>;
};

export default DeletedUserAlert;
