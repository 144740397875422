const translations = {
    loading: 'Iekraušana',
    loading_data: 'Datu ielāde ...',
    validate_email: 'Tā nav pareiza e-pasta adrese',
    validate_required: 'Šis lauks ir obligāts',
    validate_the_same: 'Abām parolēm jābūt vienādām',
    sign_in: 'Pieslēgties',
    sign_up__invitation: 'Vai jums nav konts?',
    sign_up: 'Reģistrēties!',
    forgot_password: 'Vai jūs atceraties paroli?',
    error_occurred: 'Radās kļūda!',
    activation_token_broken: 'Aktivizācijas marķieris ir nepareizs.',
    return_to_login: 'Atgriezties pieteikties',
    activation_success: 'Paldies! Jūsu konts tagad ir aktīvs.',
    forgot_password_submit: 'Atiestatiet paroli',
    password: 'Parole',
    password_confirmation: 'atkārtojiet paroli',
    email: 'E-pasta ziņojums',
    remember_me: 'Atceries mani',
    welcome: 'Laipni lūdzam!',
    log_in_to_access: 'Piesakieties, lai sāktu',
    password_forgot_message: 'Vai jūs nevarat pieteikties? Ievadiet savu e-pasta adresi, lai atiestatītu paroli!',
    back_to: 'atgriezties',
    sign_in_page: 'Pieslēgties',
    select: 'Izvēlieties ...',
    select_new: 'Izveidot:',
    invitation_sent: 'Uzaicinājums nosūtīts',
    users_custom_company_data: 'Izmantojiet citas uzņēmuma datus',
    or_just_attachment_name: 'Vai vienkārši pievieno pielikuma nosaukumu:',
    other_docs: 'Citi dokumenti:',
    readBy: 'Lasīts:',
    delete_user: 'Dzēst šo lietotāju',
    delete_user_confirm: 'Vai tiešām vēlaties dzēst šo lietotāju?',
    deleted_user_success: 'Lietotājs ir veiksmīgi dzēsts',
    transport_one_time: 'Vienreizējs transports',
    trailers_registration_number: 'Piekabes reģistrācijas numurs',
    vehicle_registration_number: 'Transportlīdzekļa reģistrācijas numurs',
    one_time_carrier_form_title: 'Lai piekļūtu šim transportam, ievadiet savu tālruņa numuru un PIN kodu',
    one_time_carrier_form_error: 'Neizdevās piekļūt transportam. Pārbaudiet datus un mēģiniet vēlreiz.',
    one_time_carrier_form_submit: 'Pārbaudīt transportu',
    phone: 'Telefons',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Jums ir piekļuve šādam transportam:',
    one_time_carrier_ecmr: 'e-CMR dokuments:',
    one_time_carrier_signatures: 'Paraksti:'
};
export default translations;
