const translations = {
    loading: 'Bezig met laden',
    loading_data: 'Gegevens laden ...',
    validate_email: 'Dit is niet het juiste e-mailadres',
    validate_required: 'dit veld is verplicht',
    validate_the_same: 'Beide wachtwoorden moeten hetzelfde zijn',
    sign_in: 'Log in',
    sign_up__invitation: 'Heb je geen account?',
    sign_up: 'Register!',
    forgot_password: 'Herinner je het wachtwoord niet?',
    error_occurred: 'Er is een fout opgetreden!',
    activation_token_broken: 'Activatietoken is onjuist.',
    return_to_login: 'Keer terug naar inloggen',
    activation_success: 'Bedankt! Uw account is nu actief.',
    forgot_password_submit: 'Reset het wachtwoord',
    password: 'Wachtwoord',
    password_confirmation: 'herhaal wachtwoord',
    email: 'E-mail',
    remember_me: 'Onthoud me',
    welcome: 'Welkom!',
    log_in_to_access: 'Log in om te starten',
    password_forgot_message: 'Kan je niet inloggen? Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen!',
    back_to: 'terugkrijgen',
    sign_in_page: 'Log in',
    select: 'Kiezen...',
    select_new: 'Creëren:',
    invitation_sent: 'Uitnodiging verzonden',
    users_custom_company_data: 'Gebruik andere bedrijfsgegevens',
    or_just_attachment_name: 'Of voeg gewoon de naam van de bijlage toe:',
    other_docs: 'Andere documenten:',
    readBy: 'Gelezen door:',
    delete_user: 'Verwijder deze gebruiker',
    delete_user_confirm: 'Weet u zeker dat u deze gebruiker wilt verwijderen?',
    deleted_user_success: 'Gebruiker is succesvol verwijderd',
    transport_one_time: 'Eenmalig transport',
    trailers_registration_number: 'Kenteken van de aanhanger',
    vehicle_registration_number: 'Kenteken van het voertuig',
    one_time_carrier_form_title: 'Voer uw telefoonnummer en PIN in om toegang te krijgen tot dit transport',
    one_time_carrier_form_error: 'Toegang tot transport mislukt. Controleer uw gegevens en probeer het opnieuw.',
    one_time_carrier_form_submit: 'Controleer transport',
    phone: 'Telefoon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'U hebt toegang verkregen tot het volgende transport:',
    one_time_carrier_ecmr: 'e-CMR document:',
    one_time_carrier_signatures: 'Handtekeningen:'
};
export default translations;
