/* eslint-disable camelcase */
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Yup from 'services/translatedYup';
import {
    classNames,
    defaultTranslations,
    translations,
} from 'services';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import {
    transportDraftDriverOption,
    transportDraftOneTimeCarrier,
    transportDraftValid,
    transportDraftVisited,
} from 'data/transportDraft/actions';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import styles from './TransportOneTimeDriver.module.scss';


const TransportOneTimeDriver = ({
    edit, push, notValid, goBack, oneTimeCarrier,
    visitedDriver, countries,
}) => {
    const lang = localStorage.getItem('language') || 'en';
    const formTranslations = {
        name: translations('front.settings.my_account.name'),
        surname: translations('front.settings.my_account.surname'),
        phone: translations('front.settings.my_account.phone'),
        vehicle_registration_number: defaultTranslations('vehicle_registration_number', lang),
        trailers_registration_number: defaultTranslations('trailers_registration_number', lang),
    };
    const reference = useRef(null);
    const [isCancel, setIsCancel] = useState(false);
    const validationObject = {
        name: Yup.string().required(),
        surname: Yup.string().required(),
        phone: Yup.string().required(),
        vehicle_registration_number: Yup.string(),
        trailers_registration_number: Yup.string(),
        company_name: Yup.string().required(),
        company_nip: Yup.string().required(),
        company_address_country_id: Yup.number().required().positive().integer(),
        company_address_postcode: Yup.string().required().min(2),
        company_address_city: Yup.string().required().min(2),
        company_address_street: Yup.string().required().min(2),
        company_address_housenumber: Yup.string(),
    };
    const validationSchema = Yup.object().shape(validationObject);
    const defaultsToUse = oneTimeCarrier && oneTimeCarrier?.name?.length > 0 ? {
        name: oneTimeCarrier.name?.length > 0 ? oneTimeCarrier.name : '',
        surname: oneTimeCarrier.surname?.length > 0 ? oneTimeCarrier.surname : '',
        phone: oneTimeCarrier?.phone.length > 0 ? oneTimeCarrier.phone : '',
        vehicle_registration_number: oneTimeCarrier?.vehicle_registration_number.length > 0 ? oneTimeCarrier.vehicle_registration_number : '',
        trailers_registration_number: oneTimeCarrier?.trailers_registration_number.length > 0 ? oneTimeCarrier.trailers_registration_number : '',
        // additional_info:
        company_address_country_id: oneTimeCarrier?.company_address_country_id ? oneTimeCarrier.company_address_country_id : '',
        company_address_postcode: oneTimeCarrier?.company_address_postcode.length > 0 ? oneTimeCarrier.company_address_postcode : '',
        company_address_city: oneTimeCarrier?.company_address_city.length > 0 ? oneTimeCarrier.company_address_city : '',
        company_address_street: oneTimeCarrier?.company_address_street.length > 0 ? oneTimeCarrier.company_address_street : '',
        company_address_housenumber: oneTimeCarrier?.company_address_housenumber.length > 0 ? oneTimeCarrier.company_address_housenumber : '',
        company_name: oneTimeCarrier?.company_name.length > 0 ? oneTimeCarrier.company_name : '',
        company_nip: oneTimeCarrier?.company_nip.length > 0 ? oneTimeCarrier.company_nip : '',
    } : {
        name: '',
        surname: '',
        phone: '',
        vehicle_registration_number: '',
        trailers_registration_number: '',
        company_address_country_id: '',
        company_address_postcode: '',
        company_address_city: '',
        company_address_street: '',
        company_address_housenumber: '',
        company_name: '',
        company_nip: '',
    };

    const goBackToSwitcher = () => {
        setIsCancel(true);
    };

    useEffect(() => {
        if (isCancel) {
            goBack(edit);
        }
    }, [edit, goBack, isCancel]);

    useEffect(() => () => {
        if (reference.current) {
            reference.current.validateForm()
                .then((e) => {
                    if (Object.keys(e).length > 0) {
                        notValid(edit);
                        visitedDriver();
                    }
                });
            reference.current.submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="TransportAdd__card">
            <Formik
                enableReinitialize
                innerRef={reference}
                initialValues={defaultsToUse}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log('submit', values);
                    if (!isCancel) {
                        push(values, edit);
                    }
                    visitedDriver();
                }}
            >
                {(f) => {
                    console.log(f);
                    return (
                        <Form>
                            <FormRow>
                                <FormField
                                    name="name"
                                    isRequired
                                    id="driver_one_time_name"
                                    label={formTranslations.name}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="surname"
                                    isRequired
                                    id="driver_one_time_surname"
                                    label={formTranslations.surname}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="vehicle_registration_number"
                                    id="driver_one_time_vehicle_registration_number"
                                    label={formTranslations.vehicle_registration_number}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="trailers_registration_number"
                                    id="driver_one_time_trailers_registration_number"
                                    label={formTranslations.trailers_registration_number}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="company_name"
                                    isRequired
                                    id="driver_one_time_company_name"
                                    label={translations('passing.name')}
                                />
                                <FormField
                                    name="company_nip"
                                    isRequired
                                    id="driver_one_time_company_nip"
                                    label={translations('front.settings.company.nip')}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="phone"
                                    isRequired
                                    isPhone
                                    id="driver_one_time_phone"
                                    label={formTranslations.phone}
                                />
                                <FormField
                                    name="company_address_country_id"
                                    isSelect={countries}
                                    label={translations('front.settings.company.address_country')}
                                    isRequired
                                    withValue
                                />
                            </FormRow>
                            <div className={styles.Group}>
                                <FormRow className={classNames(styles.AddressRow, styles.postcode)}>
                                    <FormField
                                        name="company_address_postcode"
                                        isRequired
                                        id="driver_one_time_company_address_postcode"
                                        label={translations('front.settings.company.address_postcode')}
                                    />
                                    <FormField
                                        name="company_address_city"
                                        isRequired
                                        id="driver_one_time_company_address_city"
                                        label={translations('front.settings.company.address_city')}
                                    />
                                </FormRow>
                                <FormRow className={styles.AddressRow}>
                                    <FormField
                                        name="company_address_street"
                                        isRequired
                                        id="driver_one_time_company_address_street"
                                        label={translations('front.settings.company.address_street')}
                                    />
                                    <FormField
                                        name="company_address_housenumber"
                                        id="driver_one_time_company_address_housenumber"
                                        label={translations('front.settings.company.address_housenumber')}
                                    />
                                </FormRow>
                            </div>
                            <div className={styles.Buttons}>
                                <Button theme="outline" block type="button" role="button" onClick={() => { goBackToSwitcher(); }}>{translations('front.general.cancel')}</Button>
                                <Button
                                    theme="blue"
                                    block
                                    type="submit"
                                >
                                    {translations('front.general.add')}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

TransportOneTimeDriver.propTypes = {
    edit: PropTypes.bool,
    push: PropTypes.func.isRequired,
    notValid: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    visitedDriver: PropTypes.func.isRequired,
    oneTimeCarrier: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        vehicle_registration_number: PropTypes.string.isRequired,
        trailers_registration_number: PropTypes.string.isRequired,
        company_address_country_id: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number,
        ]),
        company_address_postcode: PropTypes.string,
        company_address_city: PropTypes.string,
        company_address_street: PropTypes.string,
        company_address_housenumber: PropTypes.string,
        company_name: PropTypes.string,
        company_nip: PropTypes.string,
    }).isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

TransportOneTimeDriver.defaultProps = {
    edit: false,
};

const mapStateToProps = (state) => {
    const { transportDraft, countries } = state;
    const { oneTimeCarrier } = transportDraft;


    return {
        oneTimeCarrier,
        countries: countries.countries,
    };
};


const mapDispatchToProps = (dispatch) => ({
    push: (values, edit = false) => dispatch(transportDraftOneTimeCarrier(values, edit)),
    notValid: (edit = false) => dispatch(transportDraftValid(false, 'driver', edit)),
    goBack: (edit = false) => {
        dispatch(transportDraftOneTimeCarrier({
            name: '',
            surname: '',
            phone: '',
            vehicle_registration_number: '',
            trailers_registration_number: '',
            company_address_country_id: '',
            company_address_postcode: '',
            company_address_city: '',
            company_address_street: '',
            company_address_housenumber: '',
            company_name: '',
            company_nip: '',
        }, edit));
        return dispatch(transportDraftDriverOption(1));
    },
    visitedDriver: () => dispatch(transportDraftVisited(true, 'driver')),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportOneTimeDriver);
