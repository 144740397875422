const translations = {
    loading: 'Caricamento in corso',
    loading_data: 'Caricamento dei dati ...',
    validate_email: "Questo non è l'indirizzo email corretto",
    validate_required: 'Questo campo è obbligatorio',
    validate_the_same: 'Entrambe le password devono essere uguali',
    sign_in: 'Registrazione',
    sign_up__invitation: 'Non hai un account?',
    sign_up: 'Registrati!',
    forgot_password: 'Non ricordi la password?',
    error_occurred: 'Si è verificato un errore!',
    activation_token_broken: 'Il token di attivazione non è corretto.',
    return_to_login: 'Torna al login.',
    activation_success: 'Grazie! Il tuo account è ora attivo.',
    forgot_password_submit: 'Resettare la password',
    password: "Parola d'ordine",
    password_confirmation: 'Ripeti la password',
    email: 'E-mail',
    remember_me: 'Ricordati di me',
    welcome: 'Benvenuto!',
    log_in_to_access: 'Accedi per iniziare',
    password_forgot_message: 'Non puoi accedere? Inserisci il tuo indirizzo email per ripristinare la password!',
    back_to: 'tornare a',
    sign_in_page: 'Accedere',
    select: 'Scegliere...',
    select_new: 'Creare:',
    invitation_sent: 'Invito spedito',
    users_custom_company_data: 'Usa altri dati aziendali',
    or_just_attachment_name: 'Oppure aggiungi solo il nome dell\'allegato:',
    other_docs: 'Altri documenti:',
    readBy: 'Letto da:',
    delete_user: 'Elimina questo utente',
    delete_user_confirm: 'Sei sicuro di voler eliminare questo utente?',
    deleted_user_success: "L'utente è stato eliminato con successo",
    transport_one_time: 'Trasporto una tantum',
    trailers_registration_number: 'Numero di targa del rimorchio',
    vehicle_registration_number: 'Numero di targa del veicolo',
    one_time_carrier_form_title: 'Per accedere a questo trasporto, inserisci il tuo numero di telefono e PIN',
    one_time_carrier_form_error: 'Accesso al trasporto fallito. Verifica i dati e riprova.',
    one_time_carrier_form_submit: 'Controlla trasporto',
    phone: 'Telefono',
    pin: 'PIN',
    one_time_carrier_passing_header: "Hai ottenuto l'accesso al seguente trasporto:",
    one_time_carrier_ecmr: 'Documento e-CMR:',
    one_time_carrier_signatures: 'Firme:'
};
export default translations;
