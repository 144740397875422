const translations = {
    loading: 'Učitavam',
    loading_data: 'Učitavanje podataka ...',
    validate_email: 'Ovo nije ispravna adresa e-pošte',
    validate_required: 'ovo polje je obavezno',
    validate_the_same: 'Obje lozinke moraju biti iste',
    sign_in: 'Prijaviti se',
    sign_up__invitation: 'Nemate li račun?',
    sign_up: 'Registar!',
    forgot_password: 'Ne sjećaš li se lozinke?',
    error_occurred: 'Došlo je do pogreške!',
    activation_token_broken: 'Aktivacijski token je netočan.',
    return_to_login: 'Povratak na prijavu',
    activation_success: 'Hvala vam! Vaš je račun sada aktivan.',
    forgot_password_submit: 'Resetirajte lozinku',
    password: 'Lozinka',
    password_confirmation: 'ponovi lozinku',
    email: 'E-mail',
    remember_me: 'Zapamti me',
    welcome: 'Dobrodošli!',
    log_in_to_access: 'Prijavite se na početak',
    password_forgot_message: 'Ne možete se prijaviti? Unesite svoju adresu e-pošte da biste poništili zaporku!',
    back_to: 'vratiti se',
    sign_in_page: 'prijaviti se',
    select: 'Odaberite ...',
    select_new: 'Stvoriti:',
    invitation_sent: 'Pozivnica poslana',
    users_custom_company_data: 'Koristite druge tvrtke podatke',
    or_just_attachment_name: 'Ili samo dodajte ime privitka:',
    other_docs: 'Ostali dokumenti:',
    readBy: 'Pročitao:',
    delete_user: 'Izbriši ovog korisnika',
    delete_user_confirm: 'Jeste li sigurni da želite izbrisati ovog korisnika?',
    deleted_user_success: 'Korisnik je uspješno izbrisan',
    transport_one_time: 'Jednokratni prijevoz',
    trailers_registration_number: 'Registracijski broj prikolice',
    vehicle_registration_number: 'Registracijski broj vozila',
    one_time_carrier_form_title: 'Za pristup ovom prijevozu unesite svoj broj telefona i PIN',
    one_time_carrier_form_error: 'Pristup prijevozu nije uspio. Provjerite podatke i pokušajte ponovo.',
    one_time_carrier_form_submit: 'Provjeri prijevoz',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Dobili ste pristup sljedećem prijevozu:',
    one_time_carrier_ecmr: 'e-CMR dokument:',
    one_time_carrier_signatures: 'Potpisi:'
};
export default translations;
