import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import qs from 'qs';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER
} from '../submissionStatuses';

const useTransportDriver = (status, carrier, contractor, transport = false, oneTimeCarrier) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    const addMyCarrier = () => {
        if (carrier) {
            setIsLoading((v) => v + 1);
            API.post(`/passings/${transport.id}/carriers`, qs.stringify(carrier))
                .then(() => {
                    setIsLoading((v) => v - 1);
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setIsLoading((v) => v - 1);
                        setError(err);
                    });
                });
        }
    };

    const inviteToPassing = () => {
        setIsLoading((v) => v + 1);
        API.post(`/passings/${transport.id}/invites`, qs.stringify({ to_email: contractor.email }))
            .then(() => {
                setIsLoading((v) => v - 1);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoading((v) => v - 1);
                    setError(err);
                });
            });
    };

    useEffect(() => {
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER) {
            if (oneTimeCarrier?.name?.length > 0
                && oneTimeCarrier?.phone?.length > 0
                && oneTimeCarrier?.surname?.length > 0
            ) {
                const customCompany = {
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_name && oneTimeCarrier.company_name.length > 0 ? { name: oneTimeCarrier.company_name } : {}),
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_nip && oneTimeCarrier.company_nip.length > 0 ? { nip: oneTimeCarrier.company_nip } : {}),
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_address_country_id && Number.isInteger(oneTimeCarrier.company_address_country_id)
                        ? { address_country_id: oneTimeCarrier.company_address_country_id }
                        : {}),
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_address_postcode && oneTimeCarrier.company_address_postcode.length > 0
                        ? { address_postcode: oneTimeCarrier.company_address_postcode }
                        : {}),
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_address_city && oneTimeCarrier.company_address_city.length > 0
                        ? { address_city: oneTimeCarrier.company_address_city }
                        : {}),
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_address_street && oneTimeCarrier.company_address_street.length > 0
                        ? { address_street: oneTimeCarrier.company_address_street }
                        : {}),
                    // eslint-disable-next-line max-len
                    ...(oneTimeCarrier.company_address_housenumber && oneTimeCarrier.company_address_housenumber.length > 0
                        ? { address_housenumber: oneTimeCarrier.company_address_housenumber }
                        : {}),
                };

                API.post(`/passings/${transport.id}/one-time-carrier`, qs.stringify({
                    name: oneTimeCarrier.name,
                    phone: oneTimeCarrier.phone,
                    surname: oneTimeCarrier.surname,
                    ...(oneTimeCarrier.vehicle_registration_number
                    && oneTimeCarrier.vehicle_registration_number.length > 0
                        ? {
                            vehicle_registration_number:
                            oneTimeCarrier.vehicle_registration_number,
                        }
                        : {}),
                    ...(oneTimeCarrier.trailers_registration_number
                    && oneTimeCarrier.trailers_registration_number.length > 0
                        ? {
                            trailers_registration_number:
                            oneTimeCarrier.trailers_registration_number,
                        }
                        : {}),
                    ...(Object.keys(customCompany).length > 0
                        ? { company_data: customCompany } : {}),
                }))
                    .then(() => {
                        setIsLoading((v) => v - 1);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setIsLoading((v) => v - 1);
                            setError(err);
                        });
                    });
            } else if (contractor?.email?.length > 0) {
                if (transport?.invitations?.length) {
                    if (transport.invitations[0].to_email !== contractor.email) {
                        inviteToPassing();
                    }
                } else if (transport.current_carrier) {
                    if (transport.current_carrier.email !== contractor.email) {
                        if (transport?.carriers?.length) {
                            setIsLoading((v) => v + 1);
                            API.delete(`/passings/${transport.id}/carriers/${transport.carriers[0].id}`)
                                .then(() => {
                                    setIsLoading((v) => v - 1);
                                    inviteToPassing();
                                })
                                .catch((err) => {
                                    errorHandler(err, () => {
                                        setIsLoading((v) => v - 1);
                                        setError(err);
                                    });
                                });
                        } else {
                            inviteToPassing();
                        }
                    }
                } else {
                    inviteToPassing();
                }
            } else if (transport?.carriers?.length > 0) {
                if (transport.carriers[0].company_id === transport.company_id) {
                    setIsLoading((v) => v + 1);
                    API.put(`/passings/${transport.id}/carriers/${transport.carriers[0].id}`, qs.stringify(carrier))
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                } else {
                    setIsLoading((v) => v + 1);
                    API.delete(`/passings/${transport.id}/carriers/${transport.carriers[0].id}`)
                        .then(() => {
                            setIsLoading((v) => v - 1);
                            addMyCarrier();
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            } else {
                addMyCarrier();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carrier, contractor, status, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportDriver;
