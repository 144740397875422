import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, classNames, defaultTranslations } from 'services';
import { useAuth } from 'pages/OneTimeCarrier/data/OneTimeCarrierAuth';
import PassingInvitationAnswer from 'pages/Passing/components/PassingInvitationAnswer';
import translations from 'pages/../services/translations';
import PassingPoint from 'pages/Passing/components/PassingPoint';
import PassingDataSingleLoad from 'pages/Passing/components/PassingData/PassingDataSingleLoad';
import PassingSignatures from 'pages/Passing/components/PassingSignatures';
import RouteMap from 'components/RouteMap';
import styles from './OneTimeCarrierPassing.module.scss';

const OneTimeCarrierPassing = () => {
    const { token, setUserName, setToken } = useAuth();
    const { id } = useParams();
    const [transport, setTransport] = useState(null);
    const lang = localStorage.getItem('language') ?? 'en';
    const oneTimeTranslations = {
        header: defaultTranslations('one_time_carrier_passing_header', lang),
        ecmr: defaultTranslations('one_time_carrier_ecmr', lang),
        signatures: defaultTranslations('one_time_carrier_signatures', lang),
    };

    useEffect(() => {
        const includes = [
            'startingAddress',
            'destinationAddress',
            'sender',
            'receiver',
            'company',
            'createdBy',
            'passingLoads',
            'payments',
            'carriers',
            'signatures',
            'additionalDocs',
            'invitations',
            'adrLoads',
            'currentCarrier',
        ];
        const includeString = `include=${includes.join(',')}`;

        API.get(`/passings/${id}?${includeString}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            const { data } = response.data;
            if (typeof data === 'object' && Object.keys(data).length > 0) {
                setTransport(data);
            }
        }).catch((error) => {
            console.error(error);
            setUserName('');
            setToken('');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, token]);

    if (!transport) {
        return <div>Loading...</div>;
    }

    const defaultPointStructure = {
        title: '',
        address_city: '',
        address_country: '',
        address_housenumber: '',
        address_postcode: '',
        address_street: '',
        address_apartment_number: '',
        email: '',
        additional_info: '',
        additional_email: '',
    };

    const points = {
        sender: defaultPointStructure,
        receiver: defaultPointStructure,
        starting_address: defaultPointStructure,
        destination_address: defaultPointStructure,
    };

    [transport.sender,
        transport.receiver,
        transport.starting_address,
        transport.destination_address]
        .forEach((elm, index) => {
            if (!elm) {
                return;
            }
            let name = '';
            switch (index) {
            case 0:
                name = 'sender';
                break;
            case 1:
                name = 'receiver';
                break;
            case 2:
                name = 'starting_address';
                break;
            case 3:
                name = 'destination_address';
                break;
            default:
                break;
            }
            if (!name || name.length === 0) {
                return;
            }

            points[name] = {
                title: elm.name,
                address_city:
                    elm.address_city ? elm.address_city : '',
                address_country:
                    elm.address_country ? elm.address_country : '',
                address_housenumber:
                    elm.address_housenumber ? elm.address_housenumber : '',
                address_postcode:
                    elm.address_postcode ? elm.address_postcode : '',
                address_street:
                    elm.address_street ? elm.address_street : '',
                address_apartment_number:
                    elm.address_apartment_number ? elm.address_apartment_number : '',
                email: elm.email ? elm.email : '',
                additional_info: elm.additional_info ? elm.additional_info : '',
                additional_email: elm.additional_email ? elm.additional_email : '',
            };
        });

    let startLocation = transport.starting_address
        ? transport.starting_address.full_address
        : transport.sender.full_address;

    let endLocation = transport.destination_address
        ? transport.destination_address.full_address
        : transport.receiver.full_address;

    if (transport.starting_address) {
        if (transport.starting_address.lat && `${transport.starting_address.lat}`.length && transport.starting_address.lng && `${transport.starting_address.lng}`.length) {
            startLocation = {
                lat: typeof transport.starting_address.lat !== 'number' ? parseFloat(transport.starting_address.lat) : transport.starting_address.lat,
                lng: typeof transport.starting_address.lng !== 'number' ? parseFloat(transport.starting_address.lng) : transport.starting_address.lng,
            };
        }
    } else if (transport.sender.lat && `${transport.sender.lat}`.length && transport.sender.lng && `${transport.sender.lng}`.length) {
        startLocation = {
            lat: typeof transport.sender.lat !== 'number' ? parseFloat(transport.sender.lat) : transport.sender.lat,
            lng: typeof transport.sender.lng !== 'number' ? parseFloat(transport.sender.lng) : transport.sender.lng,
        };
    }

    if (transport.destination_address) {
        if (transport.destination_address.lat && `${transport.destination_address.lat}`.length && transport.destination_address.lng && `${transport.destination_address.lng}`.length) {
            endLocation = {
                lat: typeof transport.destination_address.lat !== 'number' ? parseFloat(transport.destination_address.lat) : transport.destination_address.lat,
                lng: typeof transport.destination_address.lng !== 'number' ? parseFloat(transport.destination_address.lng) : transport.destination_address.lng,
            };
        }
    } else if (transport.receiver.lat && `${transport.receiver.lat}`.length && transport.receiver.lng && `${transport.receiver.lng}`.length) {
        endLocation = {
            lat: typeof transport.receiver.lat !== 'number' ? parseFloat(transport.receiver.lat) : transport.receiver.lat,
            lng: typeof transport.receiver.lng !== 'number' ? parseFloat(transport.receiver.lng) : transport.receiver.lng,
        };
    }


    const carrierData = transport.carriers && transport.carriers[0] ? transport.carriers[0] : null;

    let driverPosition = null;

    try {
        if (carrierData && carrierData.driver && carrierData.driver.lat && carrierData.driver.lng) {
            const driverLat = parseFloat(carrierData.driver.lat);
            const driverLng = parseFloat(carrierData.driver.lng);
            if (!Number.isNaN(driverLat) && !Number.isNaN(driverLng)) {
                driverPosition = {
                    lat: driverLat,
                    lng: driverLng,
                };
            }
        }
    } catch (e) {
        console.error(e);
    }

    return (
        <div className={styles.Container}>
            <div className={styles.Top}>
                <h2>{oneTimeTranslations.header}</h2>
            </div>
            <div className={classNames('PassingData', styles.Passing)}>
                <div className="PassingData__info">
                    <div className="PassingData__info--status">{transport.status}</div>
                    <PassingInvitationAnswer />
                </div>
                <PassingSignatures
                    cmrHeading={oneTimeTranslations.ecmr}
                    signaturesHeading={oneTimeTranslations.signatures}
                    passing={transport}
                    skipPackageNameCheck
                    authToken={token}
                />
                <div className="PassingData__points">
                    <div className="PassingData__points--col">
                        <PassingPoint
                            type="sender"
                            data={points.sender}
                        />
                        <PassingPoint
                            type="starting_address"
                            data={transport.starting_address
                                ? {
                                    ...points.starting_address,
                                    additional_info: points.sender.additional_info
                                } : points.sender}
                            timeStart={transport.load_time_start}
                            timeEnd={transport.load_time_end}
                        />
                    </div>
                    <div className="PassingData__points--col">
                        <PassingPoint
                            type="receiver"
                            data={points.receiver}
                        />
                        <PassingPoint
                            type="destination_address"
                            data={transport.destination_address
                                ? {
                                    ...points.destination_address,
                                    additional_info: points.receiver.additional_info
                                }
                                : points.receiver}
                            timeStart={transport.unload_time_start}
                            timeEnd={transport.unload_time_end}
                        />
                    </div>
                </div>
                <div className="PassingData__load">
                    <div className="PassingData-heading PassingData__loadr--heading">{translations('front.passing.single.load.header')}</div>
                    {
                        transport.passing_loads
                    && transport.passing_loads.length
                            ? transport.passing_loads.map((load, index) => (
                                <PassingDataSingleLoad
                                    load={load}
                                    index={index + 1}
                                    key={load.id}
                                />
                            ))
                            : <div className="PassingData__load--summary">{transport.load_summary_description}</div>
                    }
                    {
                        transport.adr_loads
                    && transport.adr_loads.length
                            ? transport.adr_loads.map((load, index) => (
                                <PassingDataSingleLoad
                                    load={load}
                                    index={index + 1}
                                    key={load.id}
                                    isAdr
                                />
                            ))
                            : ''
                    }
                    <div className="PassingDataSingleLoad">
                        <div className="PassingDataSingleLoad__title">
                            {translations('front.passing.single.load.sender_instructions')}
                        </div>
                        <div className="PassingDataSingleLoad__values">
                            {transport.sender_instructions && transport.sender_instructions.length ? transport.sender_instructions : '---'}
                        </div>
                    </div>
                    <div className="PassingDataSingleLoad">
                        <div className="PassingDataSingleLoad__title">
                            {translations('passing.special_provisions')}
                        </div>
                        <div className="PassingDataSingleLoad__values">
                            {transport.special_provisions && transport.special_provisions.length ? transport.special_provisions : '---'}
                        </div>
                    </div>
                </div>
                {/* <PassingCarrier /> */}
                <div className="PassingData__route">
                    <div className="PassingData-heading">{translations('front.passing.single.route')}</div>
                    {transport.sender && transport.receiver ? (
                        <RouteMap
                            from={startLocation}
                            to={endLocation}
                            driverPosition={driverPosition}
                        />
                    ) : ''}
                </div>
            </div>
        </div>
    );
};

export default OneTimeCarrierPassing;
