const translations = {
    loading: 'Indlæser',
    loading_data: 'Data loading ...',
    validate_email: 'Dette er ikke den rigtige e-mail-adresse',
    validate_required: 'dette felt er påkrævet',
    validate_the_same: 'Begge adgangskoder skal være de samme',
    sign_in: 'Log ind',
    sign_up__invitation: 'Har du ikke en konto?',
    sign_up: 'Tilmeld!',
    forgot_password: 'Kan du ikke huske adgangskoden?',
    error_occurred: 'En fejl opstod!',
    activation_token_broken: 'Aktiveringstoken er forkert.',
    return_to_login: 'Tilbage til login',
    activation_success: 'Tak skal du have! Din konto er nu aktiv.',
    forgot_password_submit: 'Nulstil adgangskoden',
    password: 'Adgangskode',
    password_confirmation: 'Gentag adgangskode',
    email: 'E-mail.',
    remember_me: 'Husk mig',
    welcome: 'Velkommen!',
    log_in_to_access: 'Log ind for at starte',
    password_forgot_message: 'Kan du ikke logge ind? Indtast din e-mail-adresse for at nulstille dit kodeord!',
    back_to: 'kom tilbage til',
    sign_in_page: 'Log på',
    select: 'Vælge...',
    select_new: 'Skab:',
    invitation_sent: 'Invitation sendt',
    users_custom_company_data: 'Brug andre virksomhedsdata',
    or_just_attachment_name: 'Eller tilføj blot vedhæftningens navn:',
    other_docs: 'Andre dokumenter:',
    readBy: 'Læst af:',
    delete_user: 'Slet denne bruger',
    delete_user_confirm: 'Er du sikker på, at du vil slette denne bruger?',
    deleted_user_success: 'Brugeren blev slettet succesfuldt',
    transport_one_time: 'Engangstransport',
    trailers_registration_number: 'Registreringsnummer for trailer',
    vehicle_registration_number: 'Registreringsnummer for køretøj',
    one_time_carrier_form_title: 'Indtast dit telefonnummer og PIN-kode for at få adgang til denne transport',
    one_time_carrier_form_error: 'Kunne ikke få adgang til transporten. Tjek dine oplysninger, og prøv igen.',
    one_time_carrier_form_submit: 'Kontroller transport',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Du har fået adgang til følgende transport:',
    one_time_carrier_ecmr: 'e-CMR dokument:',
    one_time_carrier_signatures: 'Signaturer:'
};
export default translations;
