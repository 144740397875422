import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { setUser } from 'data/user/actions';
import Yup from 'services/translatedYup';
import {
    translations, API, userHandler, errorHandler, phoneValidator
} from 'services';
import './styles.scss';
import querystring from 'querystring';
import Button from 'components/Button';
import Error from 'components/Error';
import Success from 'components/Success';
import Alert from 'components/Alert';
import { CancelToken } from 'axios';

const SettingsAccountForm = ({
    name, surname, email, phone, setUserData,
}) => {
    const cancelToken = useRef(null);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formResponse, setFormResponse] = useState(null);

    const handleSubmit = (values) => {
        const data = { ...values };

        delete data.email;

        if (values.password.length === 0) {
            delete data.password;
            delete data.password_confirmation;
            delete data.old_password;
        }

        setFormError(null);
        setFormResponse(null);
        setLoading(true);
        cancelToken.current = CancelToken.source();
        API.put('/me', querystring.stringify(data), {
            cancelToken: cancelToken.current.token,
        })
            .then(
                (response) => {
                    if (response.data.meta && response.data.meta.message) {
                        setFormResponse(response.data.meta.message);
                    } else {
                        setFormResponse(null);
                    }
                    if (response.status === 200 && response.data.data.user) {
                        userHandler(response);
                        setUserData(response.data.data.user);
                    }
                    setLoading(false);
                }
            )
            .catch((error) => {
                errorHandler(error, () => {
                    setLoading(false);
                    setFormError(error);
                });
            });
    };

    const defaultValues = {
        name,
        surname,
        email,
        phone,
        password: '',
        password_confirmation: '',
        old_password: '',
    };

    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string().required(),
        surname: Yup.string().required(),
        phone: Yup.string().test('phone', (value) => phoneValidator(value)),
        email: Yup.string().email().required(),
        password: Yup.string().min(6).nullable(),
        password_confirmation: Yup.string().when('password', {
            is: (pw) => typeof pw !== 'undefined' && pw && pw.length > 0,
            then: Yup.string().oneOf([Yup.ref('password')]).required()
        }),
        old_password: Yup.string().when('password', {
            is: (pw) => typeof pw !== 'undefined' && pw && pw.length > 0,
            then: Yup.string().required()
        }),
    }), []);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="SettingsAccountForm">
            {formError && <Error errors={formError} />}
            {formResponse && <Success message={formResponse} />}
            <Formik
                initialValues={defaultValues}
                onSubmit={(values) => { handleSubmit(values); }}
                validationSchema={validationSchema}
            >
                {(formikBag) => {
                    let showPasswordAlert = false;
                    if (formikBag.values) {
                        if (formikBag.values.password.length) {
                            showPasswordAlert = true;
                        } else if (formikBag.values.old_password.length) {
                            showPasswordAlert = true;
                        } else if (formikBag.values.password_confirmation.length) {
                            showPasswordAlert = true;
                        }
                    }
                    return (
                        <Form className="SettingsAccountForm__form">
                            <div className="SettingsAccountForm__column">
                                <FormRow>
                                    <FormField
                                        name="name"
                                        type="text"
                                        label={translations('front.settings.my_account.name')}
                                        isRequired
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormField
                                        name="surname"
                                        type="text"
                                        label={translations('front.settings.my_account.surname')}
                                        isRequired
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormField
                                        name="email"
                                        type="text"
                                        label={translations('front.settings.my_account.email')}
                                        isRequired
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormField
                                        name="phone"
                                        type="text"
                                        label={translations('front.settings.my_account.phone')}
                                        isRequired
                                        isPhone
                                    />
                                </FormRow>
                            </div>
                            <div className="SettingsAccountForm__column">
                                {showPasswordAlert && (
                                    <div className="SettingsAccountForm__column__alert">
                                        <Alert>{translations('front.settings.password_notification')}</Alert>
                                    </div>
                                )}
                                <FormRow>
                                    <FormField
                                        name="old_password"
                                        type="password"
                                        label={translations('front.settings.my_account.password_current')}
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormField
                                        name="password"
                                        type="password"
                                        label={translations('front.settings.my_account.password_new')}
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormField
                                        name="password_confirmation"
                                        type="password"
                                        label={translations('front.settings.my_account.password_confirmation')}
                                    />
                                </FormRow>
                            </div>
                            <div className="SettingsAccountForm__submit">
                                <Button
                                    withLoader
                                    loading={loading}
                                    small
                                    type="submit"
                                    disabled={loading || !(formikBag.isValid)}
                                >
                                    {translations('front.general.save')}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};


SettingsAccountForm.propTypes = {
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    setUserData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        name: user.user && user.user.name ? user.user.name : '',
        surname: user.user && user.user.surname ? user.user.surname : '',
        email: user.user && user.user.email ? user.user.email : '',
        phone: user.user && user.user.phone ? user.user.phone : '',
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUserData: (u) => dispatch(setUser(u)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccountForm);
