import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { classNames, defaultTranslations, translations } from 'services';
import { transportDraftDriverOption } from 'data/transportDraft/actions';
import styles from './TransportAddSwitcher.module.scss';

const TransportAddSwitcher = ({ set }) => {
    const copy1 = translations('front.transport-add-switcher.copy1');
    const copy2 = translations('front.transport-add-switcher.copy2');
    const copy3 = translations('front.transport-add-switcher.copy3');
    const copy4 = translations('front.transport-add-switcher.copy4');
    const lang = localStorage.getItem('language') || 'en';
    const transportOneTime = defaultTranslations('transport_one_time', lang);

    const arrow = (
        <svg className={styles.Btn__svg} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={styles.Btn__path} d="M1 4.16064H10.2089" strokeWidth="0.972545" strokeLinecap="round" strokeLinejoin="round" />
            <path className={styles.Btn__path} d="M7.47559 1L10.6364 4.16077L7.47559 7.32154" strokeWidth="0.972545" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return (
        <div className="TransportAdd__card">
            <div className={styles.TransportAddSwitcher}>
                <div className={styles.TransportAddSwitcher__copy}>
                    {copy1}
                    {' '}
                    <span className="color-blue">{copy2}</span>
                    {'. '}
                    {copy3}
                    {' '}
                    <span className="color-green">{copy4}</span>
                    .
                </div>
                <div className={styles.TransportAddSwitcher__btns}>
                    <button
                        onClick={() => { set(2); }}
                        type="button"
                        className={classNames(styles.Btn, styles['Btn--in'])}
                    >
                        <span className={styles.Btn__copy}>{translations('passing.internal_type')}</span>
                        <span className={styles.Btn__select}>
                            {translations('user.select')}
                            {arrow}
                        </span>
                    </button>
                    <button
                        onClick={() => { set(3); }}
                        type="button"
                        className={classNames(styles.Btn, styles['Btn--out'])}
                    >
                        <span className={styles.Btn__copy}>{translations('passing.external_type')}</span>
                        <span className={styles.Btn__select}>
                            {translations('user.select')}
                            {arrow}
                        </span>
                    </button>
                    <button
                        onClick={() => { set(4); }}
                        type="button"
                        className={classNames(styles.Btn, styles['Btn--one-time'])}
                    >
                        <span className={styles.Btn__copy}>{transportOneTime}</span>
                        <span className={styles.Btn__select}>
                            {translations('user.select')}
                            {arrow}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

TransportAddSwitcher.propTypes = {
    set: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    set: (version) => dispatch(transportDraftDriverOption(version)),
});

export default connect(null, mapDispatchToProps)(TransportAddSwitcher);
