import React from 'react';
import defaultTranslation from 'services/defaultTranslations';
import PropTypes from 'prop-types';
import styles from './DeleteUser.module.scss';

const DeleteUser = ({ userId }) => {
    const currentUserId = !Number.isNaN(parseInt(localStorage.getItem('user_id'), 10))
        ? parseInt(localStorage.getItem('user_id'), 10)
        : 0;
    const language = localStorage.getItem('language') ?? 'en';
    const translations = {
        delete_user: defaultTranslation('delete_user', language),
    };

    if (userId === currentUserId) {
        return null;
    }

    return (
        <div className={styles.DeleteUser}>
            <button
                type="button"
                className={styles.Button}
                onClick={() => {
                    const eventToSend = new CustomEvent('ta-show-modal', {
                        detail: {
                            title: 'user_delete',
                            userId,
                        }
                    });
                    document.dispatchEvent(eventToSend);
                }}
            >
                {translations.delete_user}
            </button>
        </div>
    );
};

DeleteUser.propTypes = {
    userId: PropTypes.number.isRequired,
};

export default DeleteUser;
