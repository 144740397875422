const translations = {
    loading: 'Навантаження',
    loading_data: 'Завантаження даних ...',
    validate_email: 'Це не правильна адреса електронної пошти',
    validate_required: "Це поле є обов'язковим",
    validate_the_same: 'Обидва паролі повинні бути однаковими',
    sign_in: 'увійти',
    sign_up__invitation: 'У вас немає облікового запису?',
    sign_up: 'Реєстрація!',
    forgot_password: "Не пам'ятаєте пароль?",
    error_occurred: 'Сталася помилка!',
    activation_token_broken: 'Активаційний маркер неправильний.',
    return_to_login: 'Повернутися до входу',
    activation_success: 'Дякую! Ваш обліковий запис зараз активна.',
    forgot_password_submit: 'Скинути пароль',
    password: 'Пароль',
    password_confirmation: 'Повторіть пароль',
    email: 'Електронна пошта',
    remember_me: "Пам'ятай мене",
    welcome: 'Ласкаво просимо!',
    log_in_to_access: 'Увійдіть, щоб запустити',
    password_forgot_message: 'Ви не можете увійти? Введіть свою адресу електронної пошти, щоб скинути пароль!',
    back_to: 'повернутися до',
    sign_in_page: 'вхід',
    select: 'Виберіть ...',
    select_new: 'Створити:',
    invitation_sent: 'Запрошення надіслано',
    users_custom_company_data: 'Використовуйте інші дані компанії',
    or_just_attachment_name: 'Або просто додайте назву вкладення:',
    other_docs: 'Інші документи:',
    readBy: 'Прочитано:',
    delete_user: 'Видалити цього користувача',
    delete_user_confirm: 'Ви впевнені, що хочете видалити цього користувача?',
    deleted_user_success: 'Користувача успішно видалено',
    transport_one_time: 'Одноразове перевезення',
    trailers_registration_number: 'Реєстраційний номер причепа',
    vehicle_registration_number: 'Реєстраційний номер транспортного засобу',
    one_time_carrier_form_title: 'Для доступу до цього перевезення введіть свій номер телефону та PIN-код',
    one_time_carrier_form_error: 'Не вдалося отримати доступ до перевезення. Перевірте дані і спробуйте ще раз.',
    one_time_carrier_form_submit: 'Перевірити перевезення',
    phone: 'Телефон',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Ви отримали доступ до наступного перевезення:',
    one_time_carrier_ecmr: 'Документ e-CMR:',
    one_time_carrier_signatures: 'Підписи:'
};
export default translations;
