import React, {
    createContext, useContext, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

const AuthContext = createContext({
    token: '',
    // eslint-disable-next-line no-unused-vars
    setToken: (value) => {},
    userName: '',
    // eslint-disable-next-line no-unused-vars
    setUserName: (value) => {},
    errorMessage: '',
    // eslint-disable-next-line no-unused-vars
    setErrorMessage: (value) => {},
});

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(Cookies.get('oneTimeToken') || '');
    const [userName, setUserName] = useState(Cookies.get('oneTimeName') || '');
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        if (token) {
            Cookies.set('oneTimeToken', token, { expires: 0.0208333 }); // 30 minut w dniach
        } else {
            Cookies.remove('oneTimeToken');
        }
    }, [token]);

    useEffect(() => {
        if (userName) {
            Cookies.set('oneTimeName', userName, { expires: 0.0208333 }); // 30 minut w dniach
        } else {
            Cookies.remove('oneTimeName');
        }
    }, [userName]);

    return (
        <AuthContext.Provider value={{
            token, setToken, userName, setUserName, errorMessage, setErrorMessage
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
