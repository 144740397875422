import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'services';
import styles from './Alert.module.scss';

const Alert = ({ type, ...props }) => {
    const { children } = props;

    return <div className={classNames(styles.Alert, styles[`Alert--${type}`])}>{children}</div>;
};

Alert.propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['success', 'warning', 'danger']),
};

Alert.defaultProps = {
    children: <></>,
    type: 'warning',
};

export default Alert;
