const translations = {
    loading: 'načítání',
    loading_data: 'Načítání dat ...',
    validate_email: 'Toto není správná e-mailová adresa',
    validate_required: 'Toto pole je povinné',
    validate_the_same: 'Obě hesla musí být stejná',
    sign_in: 'Přihlásit se',
    sign_up__invitation: 'Nemáte účet?',
    sign_up: 'Registrovat!',
    forgot_password: 'Nepamatujete si heslo?',
    error_occurred: 'Nastala chyba!',
    activation_token_broken: 'Aktivační token je nesprávný.',
    return_to_login: 'Vrátit se k přihlášení',
    activation_success: 'Děkuju! Váš účet je nyní aktivní.',
    forgot_password_submit: 'Obnovit heslo',
    password: 'Heslo',
    password_confirmation: 'Zopakovat heslo',
    email: 'E-mailem',
    remember_me: 'Zapamatuj si mě',
    welcome: 'Vítejte!',
    log_in_to_access: 'Přihlaste se pro spuštění',
    password_forgot_message: 'Nemůže se přihlásit? Zadejte svou e-mailovou adresu pro obnovení hesla!',
    back_to: 'vrátit se k',
    sign_in_page: 'přihlásit se',
    select: 'Vybrat...',
    select_new: 'Vytvořit:',
    invitation_sent: 'Pozvánka odeslána',
    users_custom_company_data: 'Použijte jiné firemní údaje',
    or_just_attachment_name: 'Nebo jen přidejte název přílohy:',
    other_docs: 'Další dokumenty:',
    readBy: 'Přečteno od:',
    delete_user: 'Smazat tohoto uživatele',
    delete_user_confirm: 'Opravdu chcete smazat tohoto uživatele?',
    deleted_user_success: 'Uživatel byl úspěšně smazán',
    transport_one_time: 'Jednorázová doprava',
    trailers_registration_number: 'Registrační číslo přívěsu',
    vehicle_registration_number: 'Registrační číslo vozidla',
    one_time_carrier_form_title: 'Pro přístup k této dopravě zadejte své telefonní číslo a PIN kód',
    one_time_carrier_form_error: 'Nepodařilo se získat přístup k dopravě. Zkontrolujte údaje a zkuste to znovu.',
    one_time_carrier_form_submit: 'Zkontrolujte dopravu',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Získali jste přístup k následující dopravě:',
    one_time_carrier_ecmr: 'Dokument e-CMR:',
    one_time_carrier_signatures: 'Podpisy:'
};
export default translations;
