import React, { useEffect, useState } from 'react';
import classNames from 'services/classNames';
import styles from './OneTimeCarrier.module.scss';
import { AuthProvider } from './data/OneTimeCarrierAuth';
import OneTimeCarrierContainer from './components/OneTimeCarrierContainer';
import LangSwitcher from '../../components/LangSwitcher';
import { API } from '../../services';

const OneTimeCarrier = () => {
    const [isLoading, setIsLoading] = useState(true);
    const lang = localStorage.getItem('language') ?? 'en';
    useEffect(() => {
        API.get(`/${lang}/translation`).then((response) => {
            const { data } = response.data;
            if (typeof data === 'object' && Object.keys(data).length > 0) {
                localStorage.setItem('translations', JSON.stringify(data));
            }
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    }, [lang]);

    if (isLoading) {
        return null;
    }

    return (
        <AuthProvider>
            <div className={classNames('one-time-carrier-component', styles.OneTimeCarrier)}>
                <OneTimeCarrierContainer />
                <div className={styles.Lang}>
                    <LangSwitcher />
                </div>
            </div>
        </AuthProvider>
    );
};

export default OneTimeCarrier;
