import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import querystring from 'querystring';
import { useParams } from 'react-router-dom';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import Button from 'components/Button';
import { API, defaultTranslations, phoneValidator } from 'services';
import Yup from 'services/translatedYup';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import styles from './OneTimeCarrierForm.module.scss';
import { useAuth } from '../../data/OneTimeCarrierAuth';

const OneTimeCarrierForm = ({ phone }) => {
    const { setToken, setName } = useAuth();
    const [status, setStatus] = useState('default');
    const { id } = useParams();
    const lang = localStorage.getItem('language') || 'en';
    const translations = {
        title: defaultTranslations('one_time_carrier_form_title', lang),
        error: defaultTranslations('one_time_carrier_form_error', lang),
        submit: defaultTranslations('one_time_carrier_form_submit', lang),
        phone: defaultTranslations('phone', lang),
        pin: defaultTranslations('pin', lang),
    };
    const validationObject = {
        phone: Yup.string().test('phone', (value) => phoneValidator(value)),
        pin: Yup.string().required().min(5),
    };
    const validationSchema = Yup.object().shape(validationObject);
    const initialValues = {
        phone,
        pin: '',
    };
    const handleSubmit = (values) => {
        setStatus('loading');
        API.post(`/${lang}/one-time-carrier/token`, querystring.stringify({
            passing_id: id,
            phone: values.phone.startsWith('+') ? values.phone : `+${values.phone.trim()}`,
            pin: values.pin,
        }))
            .then((response) => {
                const { data } = response;
                const { access_token: accessToken, user_data: user } = data;
                if (typeof accessToken !== 'undefined' && accessToken.length > 0) {
                    setToken(accessToken);
                    setName(user?.name || '');
                    setStatus('');
                } else {
                    setStatus('error');
                }
            })
            .catch((error) => {
                console.error(error);
                setStatus('error');
            });
    };

    if (status === 'loading') {
        return (
            <SmallLoader dark />
        );
    }

    return (
        <div className={styles.OneTimeCarrierForm}>
            <h2 className={styles.Title}>{translations.title}</h2>
            {status === 'error' && <Error errors={translations.error} />}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form className={styles.Form}>
                    <FormRow>
                        <FormField name="phone" label={translations.phone} isRequired isPhone />
                    </FormRow>
                    <FormRow>
                        <FormField name="pin" label={translations.pin} isRequired />
                    </FormRow>
                    <div className={styles.Submit}>
                        <Button block theme="blue" type="submit">{translations.submit}</Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

OneTimeCarrierForm.propTypes = {
    phone: PropTypes.string,
};

OneTimeCarrierForm.defaultProps = {
    phone: '',
};

export default OneTimeCarrierForm;
