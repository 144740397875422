import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {
    BrowserRouter as Router, Route, Switch
} from 'react-router-dom';

import { getUser } from 'data/user/actions';
import { getChat, getNewMessages } from 'data/currentChat/actions';
import { getCountries } from 'data/countries/actions';
import { getTrailers } from 'data/trailers/actions';
import { getVehicles } from 'data/vehicles/actions';
import { getNewChats } from 'data/chatsList/actions';
import { getRoles } from 'data/roles/actions';
import { getAppVersion } from 'data/appVersion/actions';
import { getLanguages } from 'data/languages/actions';


import GeneralError from 'components/GeneralError';
import Loader from 'components/Loader';
import classNames from 'services/classNames';
import { hasPermission } from 'services';

import AppWrapper from './components/AppWrapper';

import './App.scss';
import OneTimeCarrier from './pages/OneTimeCarrier';

const generalErrorFallback = <GeneralError />;


const App = ({
    getCurrentUser, getFirstChat, user,
    chatID, getCurrentMessages, newMessageLoading, getCountriesList, getTrailersList, getRolesList,
    isDataLoading, getVehiclesList, getNewChat, newLoading, canManageRoles, getVersion, getLang,
}) => {
    const userID = user && user.user && user.user.id ? user.user.id : null;
    const { loading, isLoggedIn } = user;
    const tryToGetMessage = (cID, isLoading) => {
        try {
            if ((cID && !Number.isNaN(parseInt(cID, 10))) && !isLoading) {
                getCurrentMessages(cID);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const tryToGetNewChat = (isLoading) => {
        try {
            if (!isLoading) {
                getNewChat();
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getCurrentUser();
        const firstChatID = parseInt(localStorage.getItem('active_chat'), 10);
        if (!Number.isNaN(firstChatID)) {
            getFirstChat(firstChatID);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userID) {
            getCountriesList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    useEffect(() => {
        if (userID) {
            getTrailersList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    useEffect(() => {
        if (userID) {
            getVehiclesList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    useEffect(() => {
        if (userID) {
            if (canManageRoles) {
                getRolesList();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID, canManageRoles]);

    useEffect(() => {
        if (userID) {
            getVersion();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    useEffect(() => {
        getLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            tryToGetMessage(chatID, newMessageLoading);
            tryToGetNewChat(newLoading);
        }, 500000);
        if (!isLoggedIn) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, chatID, newMessageLoading, newLoading]);

    return (
        <Sentry.ErrorBoundary fallback={generalErrorFallback}>
            <div className={classNames('App', isLoggedIn && 'logged-in')}>
                {((loading && isLoggedIn) || isDataLoading) ? (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                ) : (
                    <Router>
                        <Switch>
                            <Route path="/one-time-carrier/transports/:id" component={OneTimeCarrier} exact />
                            <Route path="/">
                                <AppWrapper />
                            </Route>
                        </Switch>
                    </Router>
                )}
            </div>
        </Sentry.ErrorBoundary>
    );
};

App.propTypes = {
    getCurrentUser: PropTypes.func.isRequired,
    getFirstChat: PropTypes.func.isRequired,
    getCurrentMessages: PropTypes.func.isRequired,
    user: PropTypes.shape({
        loading: PropTypes.bool,
        user: PropTypes.object,
        error: PropTypes.any,
        isLoggedIn: PropTypes.bool,
        activated: PropTypes.bool,
        activationError: PropTypes.any,
        activationLoading: PropTypes.bool,
    }).isRequired,
    chatID: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    newMessageLoading: PropTypes.bool.isRequired,
    getCountriesList: PropTypes.func.isRequired,
    getTrailersList: PropTypes.func.isRequired,
    getVehiclesList: PropTypes.func.isRequired,
    getRolesList: PropTypes.func.isRequired,
    isDataLoading: PropTypes.bool.isRequired,
    getNewChat: PropTypes.func.isRequired,
    getLang: PropTypes.func.isRequired,
    newLoading: PropTypes.bool.isRequired,
    canManageRoles: PropTypes.bool.isRequired,
    getVersion: PropTypes.func.isRequired,
};

App.defaultProps = {
    chatID: null,
};

const mapStateToProps = (state) => {
    const {
        user, currentChat, countries, trailers, vehicles, chatsList,
    } = state;
    const { permissions } = user;
    const canManageRoles = hasPermission('role_manage', permissions);

    return {
        user,
        chatID: currentChat.chatID,
        newMessageLoading: currentChat.newLoading,
        isDataLoading: !!(countries.loading || trailers.loading || vehicles.loading),
        newLoading: chatsList.newLoading,
        canManageRoles,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getUser()),
    getFirstChat: (id) => dispatch(getChat(id)),
    getCurrentMessages: (id) => dispatch(getNewMessages(id, true)),
    getCountriesList: () => dispatch(getCountries()),
    getTrailersList: () => dispatch(getTrailers()),
    getVehiclesList: () => dispatch(getVehicles()),
    getRolesList: () => dispatch(getRoles()),
    getNewChat: () => dispatch(getNewChats()),
    getVersion: () => dispatch(getAppVersion()),
    getLang: () => dispatch(getLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
