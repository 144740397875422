import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopupAlerts from 'components/PopupAlerts';
import {
    API, defaultTranslations, errorHandler, translations
} from 'services';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import { useHistory } from 'react-router-dom';

const DeleteUserPopup = ({ close, userId }) => {
    const history = useHistory();
    const language = localStorage.getItem('language') ?? 'en';
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const defaultMessage = defaultTranslations('delete_user_confirm', language);

    const handleConfirm = () => {
        if (isLoading || error || error.length > 0) {
            return;
        }

        setIsLoading(true);
        setError(false);
        API.delete(`/users/${userId}`)
            .then(() => {
                setIsLoading(false);
                close();
                history.push('/users/list?deleted=1');
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });

        // close();
    };

    let message = <>{defaultMessage}</>;

    if (isLoading) {
        message = <SmallLoader dark />;
    }

    if (error || error.length > 0) {
        message = <Error errors={error} />;
    }

    return (
        <PopupAlerts
            title={translations('front.general.attention')}
            close={close}
            confirm={handleConfirm}
            hideConfirm={!!(isLoading || error)}
            type="error"
        >
            {message}
        </PopupAlerts>
    );
};

DeleteUserPopup.propTypes = {
    close: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};

export default DeleteUserPopup;
