const translations = {
    loading: 'Lueden',
    loading_data: 'Daten Luede ...',
    validate_email: 'Dëst ass net déi richteg E-Mail Adress',
    validate_required: 'Dëst Feld ass noutwendeg',
    validate_the_same: "Béid Passwierder mussen d'selwecht sinn",
    sign_in: 'Umellen',
    sign_up__invitation: 'Hutt Dir kee Kont?',
    sign_up: "Handech op d'Stollef!",
    forgot_password: 'Erënnert Dir Iech net mam Passwuert?',
    error_occurred: 'E Feeler ass geschitt!',
    activation_token_broken: 'Aktivéierungs Token ass falsch.',
    return_to_login: 'Zréck op Login',
    activation_success: 'Merci! Äre Kont ass elo aktiv.',
    forgot_password_submit: "Reset d'Passwuert",
    password: 'Passwuert',
    password_confirmation: 'Passwuert widderhuelen',
    email: 'E-Mailen',
    remember_me: 'Erënner dech u mech',
    welcome: 'Wëllkomm!',
    log_in_to_access: 'Loggt Iech un fir unzefänken',
    password_forgot_message: 'Kann Dir net aloggen? Gitt Är E-Mail Adress an fir Äert Passwuert zréckzesetzen!',
    back_to: 'zréckkomm',
    sign_in_page: 'Logbro-Login',
    select: 'Wielt ...',
    select_new: 'Erreeiten:',
    invitation_sent: 'Invitatioun geschéckt',
    users_custom_company_data: 'Benotze aner Firmendaten',
    or_just_attachment_name: 'Oder füügt einfach de Numm vun der Attachement bäi:',
    other_docs: 'Aner Dokumenter:',
    readBy: 'Geliest vun:',
    delete_user: 'Läsche dëse Benotzer',
    delete_user_confirm: 'Sidd Dir sécher, dass Dir dësen Benotzer läschen wëllt?',
    deleted_user_success: 'Den Benotzer gouf erfollegräich geläscht',
    transport_one_time: 'Eenmol Transport',
    trailers_registration_number: 'Remorque-Registréierungsnummer',
    vehicle_registration_number: 'Gefier-Registréierungsnummer',
    one_time_carrier_form_title: 'Gitt Äre Telefonsnummer an de PIN fir Zougang zu dësem Transport',
    one_time_carrier_form_error: 'Zougang zum Transport gescheitert. Iwwerpréift Är Donnéeën an probéiert nach eng Kéier.',
    one_time_carrier_form_submit: 'Iwwerpréift den Transport',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Dir hutt Zougang zum folgenden Transport:',
    one_time_carrier_ecmr: 'e-CMR Dokument:',
    one_time_carrier_signatures: 'Ënnerschrëften:'
};
export default translations;
