const translations = {
    loading: 'Betöltés',
    loading_data: 'Adatok betöltése ...',
    validate_email: 'Ez nem a helyes e-mail cím',
    validate_required: 'Ez a mező kötelező',
    validate_the_same: 'Mindkét jelszónak meg kell felelnie',
    sign_in: 'Bejelentkezés',
    sign_up__invitation: 'Nincs fiókod?',
    sign_up: 'Regisztráció!',
    forgot_password: 'Nem emlékszel a jelszóra?',
    error_occurred: 'Hiba történt!',
    activation_token_broken: 'Az aktiváló token helytelen.',
    return_to_login: 'Vissza a bejelentkezéshez',
    activation_success: 'Köszönöm! Fiókja most aktív.',
    forgot_password_submit: 'Állítsa vissza a jelszót',
    password: 'Jelszó',
    password_confirmation: 'Jelszó újra',
    email: 'Email',
    remember_me: 'Emlékezz rám',
    welcome: 'Üdvözöljük!',
    log_in_to_access: 'Jelentkezzen be a kezdetbe',
    password_forgot_message: 'Nem tud bejelentkezni? Adja meg e-mail címét a jelszó visszaállításához!',
    back_to: 'visszatér rá',
    sign_in_page: 'Belépés',
    select: 'Választ...',
    select_new: 'Teremt:',
    invitation_sent: 'Meghívó elküldve',
    users_custom_company_data: 'Használjon más vállalati adatokat',
    or_just_attachment_name: 'Vagy csak add hozzá a csatolmány nevét:',
    other_docs: 'Egyéb dokumentumok:',
    readBy: 'Olvasva:',
    delete_user: 'Törölje ezt a felhasználót',
    delete_user_confirm: 'Biztosan törölni szeretné ezt a felhasználót?',
    deleted_user_success: 'A felhasználót sikeresen töröltük',
    transport_one_time: 'Egyszeri szállítás',
    trailers_registration_number: 'Pótkocsi rendszáma',
    vehicle_registration_number: 'Jármű rendszáma',
    one_time_carrier_form_title: 'A szállításhoz adja meg telefonszámát és PIN kódját',
    one_time_carrier_form_error: 'Nem sikerült hozzáférni a szállításhoz. Ellenőrizze az adatokat, és próbálja újra.',
    one_time_carrier_form_submit: 'Ellenőrizze a szállítást',
    phone: 'Telefon',
    pin: 'PIN',
    one_time_carrier_passing_header: 'Hozzáférést kapott a következő szállításhoz:',
    one_time_carrier_ecmr: 'e-CMR dokumentum:',
    one_time_carrier_signatures: 'Aláírások:'
};
export default translations;
