const translations = {
    loading: 'Loading',
    loading_data: 'Loading data...',
    validate_email: 'This is not a valid e-mail address',
    validate_required: 'This field is required',
    validate_the_same: 'Both passwords must have the same value',
    sign_in: 'Log in',
    sign_up__invitation: 'Don\'t have an account?',
    sign_up: 'Sign up',
    forgot_password: 'Forgot your password?',
    error_occurred: 'An unexpected error occurred! Please try again.',
    activation_token_broken: 'Activation token is broken.',
    return_to_login: 'Go back to login page',
    activation_success: 'Thanks! Your account is active now.',
    forgot_password_submit: 'Reset password',
    password: 'Password',
    password_confirmation: 'Confirm password',
    email: 'E-mail',
    remember_me: 'Remember me',
    welcome: 'Welcome to',
    log_in_to_access: 'Log in to access your account',
    password_forgot_message: 'Can\'t log in? Please enter your e-mail address to reset your password!',
    back_to: 'Back to',
    sign_in_page: 'sign in',
    select: 'Select...',
    select_new: 'Create:',
    invitation_sent: 'Invitation sent',
    users_custom_company_data: 'Use different company data',
    or_just_attachment_name: 'Or just add the attachment name:',
    other_docs: 'Other documents:',
    readBy: 'Read by:',
    delete_user: 'Delete this user',
    delete_user_confirm: 'Are you sure you want to delete this user?',
    deleted_user_success: 'User has been deleted successfully',
    transport_one_time: 'One-time transport',
    trailers_registration_number: 'Trailer registration number',
    vehicle_registration_number: 'Vehicle registration number',
    one_time_carrier_form_title: 'To access this transport, enter your phone number and PIN',
    one_time_carrier_form_error: 'Failed to access transport. Check your details and try again.',
    one_time_carrier_form_submit: 'Check transport',
    phone: 'Phone',
    pin: 'PIN',
    one_time_carrier_passing_header: 'You have gained access to the following transport:',
    one_time_carrier_ecmr: 'e-CMR document:',
    one_time_carrier_signatures: 'Signatures:'
};
export default translations;
