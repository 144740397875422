import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import GetCmr from 'components/GetCmr';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { API, errorHandler, translations } from 'services';
import { CancelToken } from 'axios';
import Error from 'components/Error';
import PassingSignature from './PassingSignature';
import './styles.scss';

const PassingSignatures = ({
    passing, packageName, skipPackageNameCheck, authToken,
    cmrHeading, signaturesHeading,
}) => {
    const passingDocs = passing.additional_docs
    && passing.additional_docs.length ? passing.additional_docs : [];
    const downloadUrl = passing.cmr_url && passing.cmr_url.download_url ? passing.cmr_url.download_url : '';
    const signed = {
        sender: false,
        driver: false,
        receiver: false,
        destination_address: false,
        starting_address: false,
    };
    const cancelToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [opened, setOpened] = useState('');
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const passwordFormReference = useRef(null);

    let driverName = '';
    if (passing.carriers && passing.carriers[0] && passing.carriers[0].driver) {
        try {
            driverName = `${passing.carriers[0].driver.name} ${passing.carriers[0].driver.surname}`;
        } catch (e) {
            console.error(e);
        }
    }

    const svgToFile = () => {
        const $svgElement = document.getElementById('svg-drawing');
        let innerHtml = '';
        if ($svgElement) {
            innerHtml = $svgElement.innerHTML;
        }
        const data = `<?xml version="1.0" standalone="yes"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg width="900px" height="500px" xmlns="http://www.w3.org/2000/svg" version="1.1">'${innerHtml}</svg>`;
        return new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
    };

    const getSignatureName = () => {
        let name = '';
        if (opened === 'driver') {
            name = driverName;
        }

        const $driverField = document.getElementById('signature-name-field');

        if ($driverField) {
            name = $driverField.value;

            if (name.length === 0) {
                $driverField.focus();
            }
        }

        return name;
    };

    const getCityName = () => {
        let name = '';

        const $cityField = document.getElementById('signature-city-field');

        if ($cityField) {
            name = $cityField.value;

            if (name.length === 0) {
                $cityField.focus();
            }
        }

        return name;
    };

    const handleSubmit = () => {
        const nameToSend = getSignatureName();
        if (nameToSend.length === 0) {
            return;
        }
        const cityToSend = getCityName();
        if (cityToSend.length === 0) {
            return;
        }
        if (opened === 'driver') {
            if (!showPassword) {
                setShowPassword(true);
                return;
            }
            let canSubmit = false;
            if (passwordFormReference.current) {
                passwordFormReference.current.submitForm();

                if (passwordFormReference.current.values
                    && passwordFormReference.current.values.password
                    && passwordFormReference.current.values.password.length) {
                    canSubmit = true;
                }
            }

            if (!canSubmit) {
                return;
            }
        }

        if (!skipPackageNameCheck && packageName === 'basic') {
            const eventToSend = new CustomEvent('ta-show-modal', { detail: 'subscribe-popup' });
            document.dispatchEvent(eventToSend);
            return;
        }

        setIsLoading(true);
        const requestBody = new FormData();
        cancelToken.current = CancelToken.source();
        requestBody.append('type', opened);
        requestBody.append('name', nameToSend);
        requestBody.append('city', cityToSend);
        requestBody.append('file', svgToFile());

        if (opened === 'driver') {
            requestBody.append('password', passwordFormReference.current.values.password);
        }

        API.post(`/passings/${passing.id}/signatures`, requestBody, {
            cancelToken: cancelToken.current.token,
            ...(authToken.length > 0 && { headers: { Authorization: `Bearer ${authToken}` } }),
        })
            .then(() => {
                window.location.reload();
                setIsLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    const $content = document.querySelector('.AuthenticatedWrapper-content');
                    if ($content) {
                        $content.scrollTo(0, 0);
                    }
                    setError(err);
                    setIsLoading(false);
                });
            });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    if (passing.signatures && passing.signatures.length) {
        passing.signatures.forEach((signature) => {
            signed[signature.type] = true;
        });
    }

    return (
        <div className="PassingSignatures">
            {error && <Error errors={error} />}
            {cmrHeading.length > 0 && (
                <div className="PassingPoint__header">
                    {cmrHeading}
                </div>
            )}
            <GetCmr
                passingID={passing.id}
                title={passing.passing_idstr}
                date={passing.created_at}
                downloadUrl={downloadUrl}
                docs={passingDocs}
            />
            {signaturesHeading.length > 0 && (
                <div className="PassingPoint__header" style={{marginTop: '32px'}}>
                    {signaturesHeading}
                </div>
            )}
            {passing.sender && (
                <PassingSignature
                    type="sender"
                    isSigned={signed.sender || signed.starting_address}
                    downloadUrl={downloadUrl}
                    opened={opened}
                    click={(t) => {
                        if (!isLoading) {
                            setOpened(t);
                        }
                    }}
                    accesses={passing.accesses}
                    invitations={passing.invitations}
                    carriers={passing.carriers}
                />
            )}
            <PassingSignature
                type="driver"
                isSigned={signed.driver}
                downloadUrl={downloadUrl}
                opened={opened}
                click={(t) => {
                    if (!isLoading) {
                        setOpened(t);
                    }
                }}
                name={driverName}
                pw={showPassword}
                closePassword={() => { setShowPassword(false); }}
                formRef={passwordFormReference}
                accesses={passing.accesses}
                invitations={passing.invitations}
                carriers={passing.carriers}
            />
            {passing.receiver && (
                <PassingSignature
                    type="receiver"
                    isSigned={signed.receiver || signed.destination_address}
                    downloadUrl={downloadUrl}
                    opened={opened}
                    click={(t) => {
                        if (!isLoading) {
                            setOpened(t);
                        }
                    }}
                    accesses={passing.accesses}
                    invitations={passing.invitations}
                    carriers={passing.carriers}
                />
            )}
            {(opened) && (
                <div className="PassingSignatures__summary">
                    <div className="PassingSignatures__summary--info">
                        {translations('passing.signature_info')}
                    </div>
                    <div className="PassingSignatures__summary--btns">
                        <Button
                            theme="blue"
                            block
                            onClick={() => {
                                handleSubmit();
                            }}
                            withLoader
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            {translations('front.passing.single.signature.sign')}
                        </Button>
                        <Button
                            theme="outline"
                            block
                            onClick={() => {
                                setOpened(false);
                            }}
                        >
                            {translations('front.general.cancel')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

PassingSignatures.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object.isRequired,
    packageName: PropTypes.string.isRequired,
    skipPackageNameCheck: PropTypes.bool,
    authToken: PropTypes.string,
    cmrHeading: PropTypes.string,
    signaturesHeading: PropTypes.string,
};

PassingSignatures.defaultProps = {
    skipPackageNameCheck: false,
    authToken: '',
    cmrHeading: '',
    signaturesHeading: '',
};

const mapStateToProps = (state) => {
    const { user } = state;
    let packageName = 'basic';
    // eslint-disable-next-line camelcase
    if (user?.user?.current_subscription_id) {
        packageName = user.user.current_subscription_id;
    }

    return {
        packageName
    };
};

export default connect(mapStateToProps, null)(PassingSignatures);
